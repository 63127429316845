<template>
  <Table
    class="customer-profiling-details-bets-table"
    :columns="columns"
    :rows="bets"
    :loading="loading"
    no-data-message="No available bets"
  >
    <template #cell="{ column, row }">
      <component
        :is="getTableCellComponent(column.key)"
        :column-key="column.key"
        :field="column.field"
        :bet="row"
      />
    </template>
  </Table>
  <Button
    v-if="isLoadOlderBetsVisible"
    class="customer-profiling-details-bets-table-button"
    :loading="loading"
    @click="loadOlderBets"
  >
    Load older bets
  </Button>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Button from '@/components/common/Button';
import Table from '@/components/common/Table';
import CustomerProfilingDetailsBetsTableCell from './CustomerProfilingDetailsBetsTableCell';
import CustomerProfilingDetailsBetsTableCellToggle from './CustomerProfilingDetailsBetsTableCellToggle';
import CustomerProfilingDetailsBetsTableCellFlag from './CustomerProfilingDetailsBetsTableCellFlag';
import CustomerProfilingDetailsBetsTableCellBetId from './CustomerProfilingDetailsBetsTableCellBetId';
import CustomerProfilingDetailsBetsTableCellCompetition from './CustomerProfilingDetailsBetsTableCellCompetition';
import CustomerProfilingDetailsBetsTableCellEvent from './CustomerProfilingDetailsBetsTableCellEvent';
import CustomerProfilingDetailsBetsTableCellMarket from './CustomerProfilingDetailsBetsTableCellMarket';
import CustomerProfilingDetailsBetsTableCellSelection from './CustomerProfilingDetailsBetsTableCellSelection';
import CustomerProfilingDetailsBetsTableCellOdds from './CustomerProfilingDetailsBetsTableCellOdds';
import CustomerProfilingDetailsBetsTableCellStake from './CustomerProfilingDetailsBetsTableCellStake';
import CustomerProfilingDetailsBetsTableCellEventId from './CustomerProfilingDetailsBetsTableCellEventId';
import CustomerProfilingDetailsBetsTableCellPotentialPayout from './CustomerProfilingDetailsBetsTableCellPotentialPayout';
import CustomerProfilingDetailsBetsTableCellLimit from './CustomerProfilingDetailsBetsTableCellLimit';
import CustomerProfilingDetailsBetsTableCellBetOutcome from './CustomerProfilingDetailsBetsTableCellBetOutcome';

const tableCellComponentMap = {
  default: CustomerProfilingDetailsBetsTableCell,
  toggle: CustomerProfilingDetailsBetsTableCellToggle,
  flag: CustomerProfilingDetailsBetsTableCellFlag,
  betId: CustomerProfilingDetailsBetsTableCellBetId,
  competition: CustomerProfilingDetailsBetsTableCellCompetition,
  event: CustomerProfilingDetailsBetsTableCellEvent,
  market: CustomerProfilingDetailsBetsTableCellMarket,
  selection: CustomerProfilingDetailsBetsTableCellSelection,
  odds: CustomerProfilingDetailsBetsTableCellOdds,
  stake: CustomerProfilingDetailsBetsTableCellStake,
  eventId: CustomerProfilingDetailsBetsTableCellEventId,
  potentialPayout: CustomerProfilingDetailsBetsTableCellPotentialPayout,
  limit: CustomerProfilingDetailsBetsTableCellLimit,
  betOutcome: CustomerProfilingDetailsBetsTableCellBetOutcome,
};

export default {
  components: {
    ...tableCellComponentMap,
    Button,
    Table,
  },
  emits: {
    loadOlderBets: {},
  },
  setup() {
    const store = useStore();

    const columns = computed(() => store.getters['betTicker/customerBetsColumns']);
    const bets = computed(() => store.getters['betTicker/customerBetsData']);
    const loading = computed(() => store.getters['betTicker/customerBetsIsLoading']);

    const getTableCellComponent = (columnId) => {
      const foundTableCell = tableCellComponentMap[columnId];
      if (!foundTableCell) return tableCellComponentMap.default;
      return foundTableCell;
    };

    const isLoadOlderBetsVisible = computed(() => bets.value.length !== store.getters.customerBetsTotalCount);
    const loadOlderBets = () => {
      store.dispatch('betTicker/loadMoreCustomer');
    };

    return {
      columns,
      bets,
      loading,
      getTableCellComponent,
      isLoadOlderBetsVisible,
      loadOlderBets,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-table {
  height: calc(100% - 66px);

  .empty-state {
    position: absolute;
    top: 62.5%;
    left: 50%;
    transform: translate(-50%, -50%);

    .icon {
      height: 140px;
    }
  }

  .table-row {
    cursor: default;
  }
}

.customer-profiling-details-bets-table-button {
  position: fixed;
  bottom: 30px;
  right: 50px;
  height: 32px;
}
</style>
